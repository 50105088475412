
import Swiper, { Pagination } from 'swiper';

Swiper.use([Pagination]);

const swiper = new Swiper('.awards__slider', {
  slidesPerView: '1.5',
  spaceBetween: 40,
  pagination: {
    el: ".swiper-pagination",
  },
  breakpoints: {

    320: {
      slidesPerView: 1.5,
      spaceBetween: 20
    },

    576: {
      slidesPerView: 2.5,
      spaceBetween: 30
    },

    768: {
      slidesPerView: 3.5,
      spaceBetween: 30
    },

    992: {
      slidesPerView: 4,
      spaceBetween: 40
    }
  }
});
